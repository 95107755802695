@import 'styles/utils/mixins';

.pageNotFound {
  max-width: 100vw;
  max-height: 100vh;
  height: 90vh;
  padding: 180px 0;
  line-height: 1.53;
  letter-spacing: 0.5px;
  z-index: 99999999999;
  background-color: $white !important;
  color: $black;
  font-size: 1.2rem;

  @include flex(column, center, center);

  @include full-hd {
    padding: 100px 0;
  }

  @include horizontal-mobile {
    padding: 200px 0;
  }

  p {
    @include fontSize(30px);

    @include full-hd {
      @include fontSize(18px);
    }
  }

  & > div:first-child {
    width: 20%;

    @include flex(column, center, center);

    @include tablet {
      width: calc(100% - 80px);
      margin: 0 40px;
      max-width: 50%;
    }

    @include below-tablet-horizontal {
      width: 50%;
    }

    @include phone {
      width: 100%;
    }

    .jsonWrapper {
      max-width: 100%;

      div {
        max-width: 100%;
        max-height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        will-change: transform;
        opacity: 1;
        transition: transform $default-transition-time, opacity $default-transition-time;

        @include flex(column, center, center);

        svg {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .button {
    @include button-more($black, $black, transparent, $turbo-100, $black, $turbo-100);

    border-radius: $button-border-radius;
    min-width: 290px;
    font-weight: 600;
    outline: none;

    @include fontSize(16px);

    @include higher-phone {
      min-width: 38vw;
    }

    @include phone {
      min-width: 300px;
      margin: 60px auto 0;
    }
  }
}
